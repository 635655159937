const LoadingScreen = () => {
return(
<div className="home-grid-container" style={{ height: '800px' }}>
      <div className="title-container-home">
      <h1 className="spartan-title-home">Loading...</h1>
    </div>
    </div>
)

}
export default LoadingScreen;